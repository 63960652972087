.create-title {
  font-size: 16px;
  height: 55px;
  line-height: 54px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  font-weight: 500;
}

.upload-input {
  opacity: 0;
  z-index: 10;
  height: 50px;
  position: absolute;
  top: 40px;
}

.upload-btn {
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  font-family: Arial;
  background-color: #1677ff;
  margin-bottom: 5px;
}

.file-list-item {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-item-name {
  width: 300px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.red-text {
  color: red;
}

.text-normal {
  color: 0x666666;
}