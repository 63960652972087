.relation {
  padding: 15px;
  height: calc(100vh - 55px - 30px);
}

.relation-chain {
  width: 100%;
  height: 200px;
  text-align: center;
  border: 1px solid #000;
  line-height: 200px;
}

.search-result-help {
  margin-top: 40px;
  text-align: right;
  font-size: 16px;
  color: #0068ff;
  text-decoration: underline
}