.app-title {
  background-color: #1677ff;
  padding: 15px 20px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.app-back {
  position: absolute;
  left: 20px;
}

.highlight {
  color: rgba(255, 0, 0, 1);
}

input {
  touch-action: manipulation;
}

.refresh {
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 18px;
  color: #fff;
}