.home-top {
  background-color: #1677ff;
  padding: 15px 20px;
}

.home-title {
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.home-top-name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.home-top-name-text {
  font-size: 18px;
  color: #fff;
}

.separate {
  width: 2px;
  height: 50px;
  margin: 0 20px;
  background-color: #fff;
}

.home-top-name-desc {
  width: 160px;
  height: 50px;
  padding: 0 20px;
  font-size: 15px;
  color: #fff;
  background-color: #ff3141;
  line-height: 23px;
  border-radius: 5px;
}

.home-search {
  margin-bottom: 20px;
}

.home-link {
  text-align: right;
  font-size: 16px;
  text-decoration: underline;
  color: #fff;
}

.home-content {
  padding: 20px;
}

.home-content-item {
  padding-bottom: 10px;
  position: relative;
}

.home-content-item-time {
  font-size: 14px;
  color: rgba(0,0,0,0.5);
  margin-bottom: 5px;
}

.home-content-item-title {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* 或者 display: -webkit-inline-box; 如果是内联元素 */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.home-content-item-desc {
  font-size: 16px;
  color: rgba(0,0,0,0.5);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; /* 或者 display: -webkit-inline-box; 如果是内联元素 */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.home-content-item-boder {
  position: absolute;
  top: 3px;
  left: -12px;
}

.round-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1677ff;
}

.round-border {
  width: 1px;
  height: 120px;
  background-color: rgba(0,0,0,0.2);
  margin-left: 4px;
}