.details {
  padding: 15px;
}

.details-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-lable {
  font-size: 17px;
  color: #666;
}

.form-value {
  margin-left: 10px;
  font-size: 17px;
  color: #000;
}