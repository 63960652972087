.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.search-input {
  width: 85%;
}

.search-btn {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.serch-pop {
  width: 71%;
  max-height: 200px;
  overflow: auto;
  padding: 10px 20px;
  position: absolute;
  z-index: 1001;
  background-color: #fff;
  border: 1px solid #d3dae3;
  box-shadow: 1px 1px 4px 0 rgba(17,23,32,.12);
  border-radius: 4px;
  top: 44px;
}

.serch-pop-item {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 5px;
}

.serch-pop-help {
  font-size: 14px;
  text-decoration: underline;
  color: #0068ff;
}

.recently-search {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.recently-search-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recently-search-clean {
  font-size: 16px;
  color: rgba(0, 1, 0, 0.8);
  margin-bottom: 10px;
}

.recently-search-title, .hot-search-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
}

.recently-search-box {
  display: flex;
  flex-wrap: wrap;
}

.recently-search-item {
  margin-right: 5px;
  padding: 2px 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;
}

.hot-search {
  padding: 10px;
}

.hot-search-item {
  padding-left: 5px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 10px;
}

.hot-search-item:nth-child(1) {
  color: rgba(255, 0, 0, 1);
}

.hot-search-item:nth-child(2), .hot-search-item:nth-child(3) {
  color: rgba(255, 165, 0, 1);
}

