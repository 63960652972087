.search-result {
}

.search-result-top {
  height: 29px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-result-content {
  height: calc(100vh - 55px - 30px - 50px);
  overflow: auto;
  padding: 15px;
}

.search-result-item {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.search-result-item-name {
  margin-bottom: 10px;
}

.view-detail {
  text-align: right;
  font-size: 14px;
  color: #0068ff;
  text-decoration: underline;
}

.search-result-empty {
  height: 300px;
  text-align: center;
  line-height: 300px;
  font-size: 16px;
  color: #0068ff;
  text-decoration: underline
}